/* This example requires Tailwind CSS v2.0+ */
import {ClockIcon, ShoppingBagIcon} from '@heroicons/react/solid';
import {FaParking} from 'react-icons/fa';

const features = [
    {
        name: 'Öffnungszeiten',
        description: (
            <p>
                Montag bis Freitag: 08:00 bis 18:30 Uhr
                <br/>
                Samstag: 08:00 bis 17:00 Uhr
                <br/>
                <small>Öffnungszeiten der Nachtgastronomie können abweichen, nähere Details finden Sie unter
                    Shops</small>
            </p>
        ),
        icon: ClockIcon,
    },
    {
        name: 'Shops',
        description:
            'Das B1 Center bietet mit seinem Shop-Angebot alles Wichtige und Nötige für den täglichen Bedarf und darüber hinaus.',
        icon: ShoppingBagIcon,
    },
    {
        name: 'Parken',
        description:
            'Vor dem B1 Center stehen Ihnen genügend Parkplätze kostenlos zur Verfügung.',
        icon: FaParking,
    },
];

export default function ImportantInfo() {
    return (
        <section id={'important-info'} className="relative bg-white ">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="mt-12 lg:mt-28">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 grid">
                        {features.map((feature, index) => (
                            <div
                                key={feature.name}
                                className={'pt-6 h-full aos-init'}
                                data-aos={'fade-up'}
                                data-aos-duration={600}
                                data-aos-delay={index * 100}
                            >
                                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 h-full">
                                    <div className="-mt-6">
                                        <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-red-700 p-3 shadow-lg">
                        <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                        />
                      </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
                                            {feature.name}
                                        </h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            {feature.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
