import React from "react";
import Button from "./common/Button";
import Title from "./common/Title";
import shops from "../config/shops";

export default function ShopsOverview() {
  return (
    <section id={"shops"} className={"py-32 flex align-middle"}>
      <div className="container mx-auto pt-8 lg:pt-16 px-10">
        <div
          className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto
mb-5 md:mb-10"
        >
          <Title
            data-aos={"fade-left"}
            data-aos-duration={600}
            data-aos-delay={0}
          >
            <>
              Entdecken Sie{" "}
              <span
                className={
                  "underline decoration-8 decoration-red-700 font-bold"
                }
              >
                eine Vielzahl
              </span>{" "}
              an Shops
            </>
          </Title>
        </div>
        <div className="pt-16 px-15 bg-gray-50 flex flex-wrap rounded-xl gap-12 p-12 justify-center space-y-5">
          {shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop, index) => {
              if (index >= 4) return;
              return (
                <div
                  className={`${
                    shop.invertImage ? "invert" : ""
                  } w-1/3 xl:w-1/5 flex justify-center items-center aos-init grayscale hover:grayscale-0`}
                >
                  <img
                    src={shop.image}
                    className={"aos-init xl:py-12"}
                    data-aos={"fade-left"}
                    data-aos-duration={300}
                    data-aos-delay={index * 100}
                  />
                </div>
              );
            })}
        </div>
        <div
          className={"flex align-middle justify-center mt-10 aos-init"}
          data-aos={"fade-up"}
          data-aos-duration={300}
          data-aos-delay={300}
        >
          <Button link={"/shops"} colors={{ text: "text-white", bg: "bg-red" }}>
            zur Übersicht
          </Button>
        </div>
      </div>
    </section>
  );
}
