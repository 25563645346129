import BgImage from '../images/bg_main.jpg'

export default function SmallHeader() {
  return (
      <header className="relative bg-white overflow-hidden h-56 lg:h-96">
        <img
            className="h-full w-full object-cover"
            src={BgImage}
            alt=""
        />
      </header>
  );
}
