import {useEffect} from "react";


const BASE_TITLE = 'B1 Center | '

export function useTitle(title: string): void {
  useEffect(() => {
    const prevTitle = document.title

    document.title = `${BASE_TITLE} ${title}`

    return () => {
      document.title = prevTitle
    }
  }, [])
}
