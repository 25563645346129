import { Link } from "react-router-dom";

type ButtonOptions = {
  colors: {
    bg: string;
    text: string;
  };
  classNames?: string;
  link?: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[] | string;
};

export default function Button(opt: ButtonOptions) {
  if (opt.link) {
    return (
      <Link
        to={opt.link}
        className={`${opt.colors.bg}-700 hover:${opt.colors.bg}-500 hover:translate-y-1 ease-in-out duration-300 ${opt.colors.text} ${opt.classNames} py-4 px-12 text-md xl:text-lg rounded-2xl font-medium `}
      >
        {opt.children}
      </Link>
    );
  }

  return (
    <button
      onClick={opt.onClick}
      className={`${opt.colors.bg}-700 hover:${opt.colors.bg}-500 hover:translate-y-1 ease-in-out duration-300 ${opt.colors.text} ${opt.classNames} py-4 px-12 text-md xl:text-lg rounded-2xl font-medium `}
    >
      {opt.children}
    </button>
  );
}
