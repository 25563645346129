import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo.svg";

const NavLinks: {
  title: string;
  href: string;
  root?: boolean;
}[] = [
  {
    title: "Start",
    href: "/",
    root: true,
  },
  {
    title: "Shops",
    href: "/shops",
  },
  {
    title: "News & Aktionen",
    href: "/news-und-aktionen",
  },
  {
    title: "Kontakt",
    href: "/kontakt",
  },
];

export default function Navbar() {
  const location = useLocation();

  return (
    <Disclosure
      as="nav"
      className="bg-white shadow fixed w-full top-0 left-0 z-40"
    >
      {({ open }) => (
        <>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex xl:justify-items-end justify-between w-full">
                <div className="flex-shrink-0 flex items-center">
                  <a href={"/"}>
                    <img
                      className="block h-8 w-auto"
                      src={Logo}
                      alt="B1 Center Logo"
                    />
                  </a>
                </div>
                <div className="hidden sm:ml-6 lg:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                  {NavLinks.map((link, index) => {
                    return (
                      <Link
                        to={link.href}
                        className={`${
                          link.href === location.pathname
                            ? "border-red-600"
                            : "border-transparent"
                        } ${
                          link.href === "/halloween"
                            ? "text-orange-500"
                            : "text-gray-900"
                        } border-b-4 inline-flex items-center px-1 pt-1 border-b-2 text-lg uppercase font-bold aos-animate`}
                        data-aos={"fade-up"}
                        data-aos-duration={600}
                        data-aos-delay={(index + 1) * 100}
                      >
                        {link.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={
                    "inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent"
                  }
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-9 w-9" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-9 w-9" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className={"pt-2 pb-3 space-y-1 container mx-auto"}>
              {NavLinks.map((link, index) => {
                return (
                  <Link
                    to={link.href}
                    className={`${
                      link.href === location.pathname
                        ? "border-red-600"
                        : "border-l-transparent"
                    } ${
                      link.href === "/halloween"
                        ? "text-orange-500"
                        : "text-black"
                    } hover:border-red-600 hover:border-l-4 border-indigo-500 block pl-3 pr-4 py-2 border-l-4 text-base font-bold uppercase aos-animate`}
                    data-aos={"fade-up"}
                    data-aos-duration={600}
                    data-aos-delay={(index + 1) * 100}
                  >
                    {link.title}
                  </Link>
                );
              })}
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
