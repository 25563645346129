import { useTitle } from "../hooks/useTitle";
import Title from "../components/common/Title";
import NewsEntry from "../components/news/NewsEntry";
import ImportantInfo from "../components/ImportantInfo";
import Paragraph from "../components/common/Paragraph";
import React from "react";
import news from "../config/news";

export default function News() {
  useTitle("News und Aktionen");

  return (
    <>
      <section id={"news"} className={"py-12 lg:py-24"}>
        <Title>Unsere News & Aktionen</Title>

        {news.length <= 0 ? (
          <Paragraph
            data-aos={"fade-right"}
            data-aos-duration={600}
            data-aos-delay={100}
          >
            Zur Zeit gibt es keine News oder Aktionen
          </Paragraph>
        ) : null}
        <div
          className={
            "grid grid-cols-1 xl:grid-cols-3 mt-5 lg:mt-24 md:gap-24 place-content-center place-items-center px-4 lg:px-24"
          }
        >
          {news.map((news, index) => {
            return <NewsEntry news={news} index={index} key={index} />;
          })}
        </div>
      </section>

      <div className={"mb-4 lg:mb-24"}>
        <ImportantInfo />
      </div>
    </>
  );
}
