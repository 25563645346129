import React from "react";
import {HiArrowNarrowRight} from "react-icons/hi";
import {Link} from "react-router-dom";

function Footer({hideContact}: { hideContact?: boolean }) {
  return (
      <div className={"aos-animate"} data-aos={'fade-down'}
           data-aos-duration={600}
           data-aos-delay={100}>
        {hideContact ? null :
            <div className="flex items-center justify-center">
              <div className="flex sm:w-2/3 w-11/12 mt-20 -mb-20 items-center justify-center">
                <div className="w-full bg-gray-50 rounded-lg  aos-animate z-20"
                     data-aos={'fade-down'}
                     data-aos-duration={600}
                     data-aos-delay={0}>
                  <div
                      className="py-10 lg:px-28 px-10 flex sm:flex-row flex-col items-center sm:justify-between justify-center">
                    <div>
                      <h1
                          role="heading"
                          className="lg:text-3xl text-xl font-bold text-gray-800"
                      >
                        Sie haben <span className={"text-red-700"}>noch Fragen?</span>
                      </h1>
                      <p
                          role="contentinfo"
                          className="lg:text-2xl text-lg font-medium leading-6 mt-4 text-gray-800"
                      >
                        Kontaktieren Sie uns.
                      </p>
                    </div>
                    <Link
                        to={"/kontakt"}
                        role="button"
                        aria-label="Kontakt"
                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 lg:w-28 w-16 lg:h-20 h-12 bg-gray-50 rounded-lg flex items-center justify-center md:mt-0 mt-8"
                    >
                      <HiArrowNarrowRight
                          className={
                            "text-black hover:text-red-700 text-4xl hover:text-6xl"
                          }
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
        }
        <div className="bg-gray-800 w-full h-full flex pb-16 pt-44 rounded-t-2xl sm:rounded-t-none">
          <div className="flex justify-around w-full">
            <div>
              <h2 className="text-xl font-semibold leading-5 text-white">MENU</h2>


              <div className={"mt-7"}>
                <Link to={"/"} className={"text-white hover:text-red-700 text-sm"}>
                  Start
                </Link>
              </div>


              <div className={"mt-7"}>
                <Link to={"/shops"} className={"text-white hover:text-red-700 text-sm"}>
                  Shops
                </Link>
              </div>

              <div className={"mt-7"}>
                <Link to={"/news-und-aktionen"} className={"text-white hover:text-red-700 text-sm"}>
                  News & Aktionen
                </Link>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-semibold leading-5 text-white uppercase">
                Nützliche Links
              </h2>

              <div className={"mt-7"}>
                <Link to={"/kontakt"} className={"text-white hover:text-red-700 text-sm"}>
                  Kontakt
                </Link>
              </div>

              <div className={"mt-7"}>
                <Link to={"/impressum"} className={"text-white hover:text-red-700  text-sm"}>
                  Impressum
                </Link>
              </div>

              <div className={"mt-7"}>
                <Link to={"/datenschutz"} className={"text-white hover:text-red-700 text-sm"}>
                  Datenschutz
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;
