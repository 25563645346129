import React from "react";
import { useTitle } from "../hooks/useTitle";
import Title from "../components/common/Title";

export default function Imprint() {
  useTitle("Impressum");

  return (
    <section id={"impressum"} className={"py-12 lg:py-24"}>
      <Title>Impressum</Title>
      <div className="mx-auto px-14 lg:px-0 lg:mx-16">
        <div className="w-full place-items-center place-content-center text-center">
          <p>
            Offenlegung gemäß § 25 Mediengesetz
            <br />
            Informationen gemäß § 5 E-Commerce Gesetz
          </p>
          <p className={"mt-5"}>
            <strong>LLB Immo Kapitalanlagegesellschaft m.b.H.</strong>
            <br />
            A-1010 Wien, Wipplingerstraße 35
            <br />
            Telefon: +43 1 536 16-0
            <br />
            Fax: + 43 1 536 16-900
            <br />
            E-Mail: immo@llb.at
            <br />
            www.llbimmo.at
          </p>
          <p className={"mt-5"}>
            Firmenbuchgericht: Handelsgericht Wien
            <br />
            Firmenbuchnummer: FN 245392x
          </p>
          <p className={"mt-5"}>
            Aufsichtsbehörde: Finanzmarktaufsicht, Otto-Wagner-Platz 5, A-1090
            Wien, WWW.FMA.GV.AT
            <br />
            Mitgliedschaft: Die LLB Immo Kapitalanlagegesellschaft m.b.H. ist
            Mitglied der Vereinigung Österreichischer Investmentgesellschaften
            (VÖIG).
            <br />
            Hauptgeschäftstätigkeit: Kapitalanlagegesellschaft für Immobilien
            gem. § 2 Immobilien-Investmentfondsgesetz (ImmoInvFG).
            <br />
            Anwendbare gewerbe- und berufsrechtliche Vorschriften sind
            insbesondere das ImmoInvFG und AIFMG.
            <br />
            Zugang zu diesen Vorschriften: HTTP://WWW.RIS.BKA.GV.AT
            <br />
            <br />
            <br />
            Behörde gem. ECG (E-Commerce Gesetz): Magistratisches Bezirksamt des
            I. Bezirkes
            <br />
            UID-Nummer: ATU 57879237
            <br />
          </p>

          <p className={"mt-4"}>
            <span className={"font-bold"}>Webdesign & Programmierung</span>
            <br />
            <a href={"mailto:hi@devbox.co.at"}>hi@devbox.co.at</a>
          </p>

          <h3 className={"text-2xl font-normal mt-8"}>Hausverwaltung</h3>
          <p className={"mt-5"}>
            <strong>Realverwaltung GmbH</strong>
            <br />
            Koschatstraße 129
            <br />
            9020 Klagenfurt
            <br />
            FN 249935b
            <br />
            Telefon: +43 463 50 35 50
            <br />
            Email: <a href={"mailto:office@rutter.at"}>office@rutter.at</a>
          </p>

          <h3 className={"text-2xl font-normal mt-8"}>Werbung und Marketing</h3>
          <p className={"mt-5"}>
            <strong>KAPUY Communications e.U.</strong>
            <br />
            Hauptstraße 7
            <br />
            2721 Bad Fischau-Brunn
            <br />
            Telefon: +43 2639 20320
            <br />
            Email:{" "}
            <a href={"mailto:doris.kapuy@kapuy.at"}>doris.kapuy@kapuy.at</a>
            <br />
            Web:{" "}
            <a href={"https://www.kapuy.at"} target={"_blank"} rel="noreferrer">
              www.kapuy.at
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
