import React from "react";

type ParagraphOptions = {
  children: JSX.Element | JSX.Element[] | string
}

export default function Paragraph(opt: ParagraphOptions) {
  return (
      <p className="text-base md:text-lg lg:text-xl text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto aos-init" {...opt}>
        {opt.children}
      </p>
  )
}
