import classNames from "classnames";
import { useRef, useState } from "react";
import Title from "../components/common/Title";
import ImportantInfo from "../components/ImportantInfo";
import ShopEntry from "../components/shop/ShopEntry";
import shops from "../config/shops";
import { useTitle } from "../hooks/useTitle";
import Plan from "../images/uebersichtsplan.jpg";
import { useIsVisible } from "../hooks/useIsVisisble";
import Button from "../components/common/Button";

export default function Shops() {
  useTitle("Shops");

  const [planOpen, setPlanOpen] = useState(false);
  const shopRef = useRef<HTMLDivElement>(null);

  const shopsVisible = useIsVisible(shopRef);

  const onScrollToShopsClick = () => {
    if (!shopRef.current) return;

    const offset = -150; // Adjust this value as needed
    const top = shopRef.current?.offsetTop - offset;
    window.scrollTo({ top, behavior: "smooth" });
  };

  return (
    <div className={"pb-12 lg:pb-24 relative"}>
      <section id={"shops"} className={"py-12 lg:py-24"}>
        <Title
          data-aos={"fade-left"}
          data-aos-duration={600}
          data-aos-delay={0}
        >
          Unsere Shops
        </Title>

        <div
          className={classNames(
            { "w-full 2xl:w-[80%] mx-auto cursor-pointer px-4": !planOpen },
            {
              "h-screen w-screen fixed top-0 left-0 bg-white z-50 py-2 px-2":
                planOpen,
            }
          )}
          onClick={() => {
            setPlanOpen(!planOpen);
          }}
        >
          <img
            src={Plan}
            alt="Übersichtsplan"
            className="object-contain w-full h-full"
            data-aos={"fade-up"}
          />
          {planOpen && (
            <small className="absolute w-full bottom-2 text-center text-gray-500">
              Klicken zum Schließen
            </small>
          )}
        </div>

        <div className="mt-8 lg:mt-14 mx-auto px-14 lg:px-0 lg:mx-16">
          <div
            className="grid w-full lg:grid-cols-4 md:grid-cols-3 grid-cols-1 lg:gap-x-10 gap-x-12 lg:gap-y-10 gap-y-2 lg:px-8 space-y-12 md:space-y-0 md:px-0 place-items-center place-content-center"
            ref={shopRef}
          >
            {shops.map((shop, index) => {
              return (
                <ShopEntry
                  shop={shop}
                  key={shop.name}
                  data-aos={"fade-up"}
                  data-aos-duration={600}
                  data-aos-delay={(index + 1) * 100}
                />
              );
            })}
          </div>
        </div>
      </section>
      <ImportantInfo />
      {!shopsVisible && (
        <div className="fixed bottom-0 left-0 right-0 flex justify-center pb-8">
          <div>
            <Button
              colors={{ bg: "bg-red", text: "text-white" }}
              classNames={"hover:text-white"}
              onClick={onScrollToShopsClick}
            >
              zur Shop Liste
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
