import AdmiralLogo from "../images/shops/ADMIRAL_Logo_4c.png";
import CANDA from "../images/shops/CANDA.png";
import CocktailBarLogo from "../images/shops/cocktail-bar.png";
import DanLogo from "../images/shops/dan-logo.svg";
import DMLogo from "../images/shops/dm.svg";
import FressNapfLogo from "../images/shops/fressnapf_neu.png";
import HuberShopLogo from "../images/shops/huber_shop_logo.jpg";
import JYSKLogo from "../images/shops/JYSK-logo.jpg";
import KikLogo from "../images/shops/kik_logo.svg";
import KlippLogo from "../images/shops/klipp_logo.jpg";
import PagroLogo from "../images/shops/pagro-logo.jpg";
import PetsRoyalLogo from "../images/shops/petsroyal-logo.jpg";
import Shoe4YouLogo from "../images/shops/shoe4you_logo.svg";
import TakkoLogo from "../images/shops/takko-logo.svg";
import UnimarktLogo from "../images/shops/unimarkt-logo.png";
import { Shop } from "../models/shop";

const shops: Shop[] = [
  {
    name: "C&A",
    image: CANDA,
    invertImage: false,
    description: "C&A Ybbs/Donau, B1 Center, B1 Center",
    link: "https://www.c-and-a.com/stores/at-de/niederoesterreich/ybbs-donau/b1-center-6.html",
    phone: "+4369913541312",
    email: "fashion.line@cua.at",
    openingHours: [
      {
        day: "Mo-Sa",
        time: "09:00-18:00 Uhr",
      },
      {
        day: "Sonntag",
        time: "geschlossen",
      },
      {
        day: "08.12.2023",
        time: "10:00-18:00 Uhr",
      },
      {
        day: "24.12.2023",
        time: "geschlossen",
      },
    ],
  },
  {
    name: "Cocktails by CD GmbH",
    image: CocktailBarLogo,
    invertImage: true,
    description: (
      <div className={"flex flex-row justify-center gap-5"}>
        <a
          className={"hover:text-red-700"}
          href={"https://facebook.com/cocktails.ybbs"}
        >
          Facebook
        </a>
        <a
          className={"hover:text-red-700"}
          href={"https://instagram.com/cocktailsybbs"}
        >
          Instagram
        </a>
      </div>
    ),
    link: "https://cocktails-bar.at",
    phone: "07412 / 20444",
    openingHours: [
      {
        day: "Mo-Do",
        time: "08:00-23:00 Uhr",
      },
      {
        day: "Fr-Sa",
        time: "08:00-04:00 Uhr",
      },
      {
        day: "Sonntag",
        time: "geschlossen",
      },
    ],
  },
  {
    name: "Huber Shop",
    image: HuberShopLogo,
    description: <p>Huber Shop Outlet Ybbs</p>,
    link: "https://huber-shop.com",
    email: "HSG.F749@huber-shop.com",
    phone: "+43 676 83 505 4749",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "dm drogerie markt",
    image: DMLogo,
    description: (
      <div className={"flex flex-col"}>
        <p>
          <span className={"font-bold"}>
            {" "}
            Hier bin ich Mensch, hier kauf ich ein
          </span>
          <br />
          <br />
          Eine angenehme Einkaufatmosphäre, eine große Auswahl aktueller
          Drogerieprodukte sowie eine freundliche und persönliche Beratung durch
          kompetente MitarbeiterInnen: Schönheits- und gesundheitsbewusste
          Kundinnen und Kunden kommen bei dm drogerie markt auf ihre Kosten! Das
          vielfältige Sortiment in der Filiale umfasst die Bereiche Schönheit,
          Gesundheit, Ernährung, und Haushalt, aber auch Fotoausarbeitung wird
          bei dm angeboten. Neben Top-Industriemarken erhält man auch die
          beliebten dm Marken wie beispielsweise Balea, alverde NATURKOSMETIK
          oder Denkmit. Eine Auswahl, bei der man immer genau das findet, was
          man sucht: Von gesunder Ernährung bis zu einer breiten Palette an
          Naturkosmetik. Dadurch wird bewusstes Einkaufen für die ganze Familie
          möglich.{" "}
        </p>
      </div>
    ),
    link: "https://dm.at",
    phone: "+43 741 2588 51",
    email: "info@dm.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:30-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "08:30-17:00 Uhr",
      },
    ],
  },
  {
    name: "Fressnapf",
    image: FressNapfLogo,
    description: "",
    link: "https://fressnapf.at",
    phone: "+43 7412 53197",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "KiK",
    image: KikLogo,
    description: "",
    link: "https://kik.at",
    phone: "+43 1 123 4565 9",
    openingHours: [
      {
        day: "Mo-Do",
        time: "08:30-18:00 Uhr",
      },
      {
        day: "Fr",
        time: "08:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "08:00-14:00 Uhr",
      },
    ],
  },
  {
    name: "KLIPP Frisör",
    image: KlippLogo,
    description: "",
    link: "https://klipp.at/friseur-salons/73/ybbs-an-der-donau/b1-center-8 \n",
    phone: "+43 7242 65755",
    email: "info@klipp.at",
    openingHours: [
      {
        day: "Mo-Do",
        time: "08:30-18:00 Uhr",
      },
      {
        day: "Fr",
        time: "08:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "08:00-14:00 Uhr",
      },
    ],
  },
  {
    name: "MOSTVIERTLER DAN KÜCHEN",
    image: DanLogo,
    description: "",
    link: "https://mostviertler-dan.at",
    email: "office@mostviertler-dan.at",
    phone: "+43 7412 53533",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-17:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-12:00 Uhr",
      },
    ],
  },
  {
    name: "Admiral",
    image: AdmiralLogo,
    description: (
      <div className={"flex flex-col"}>
        <p>
          <br />
          ADMIRAL ist seit 1991 einer der führenden Sportwett- &
          Glücksspiel-Anbieter, mit Sitz in Niederösterreich, und bereits seit
          vielen Jahren die absolute Nummer 1 in ganz Österreich. In über 200
          Filialen und Sportsbars ist es nicht nur möglich jedes Sporthighlight
          auf HD TV-Walls mitzuerleben, sondern auch an den neuesten
          Glücksspielgeräten abwechslungsreiche und spannende Unterhaltung zu
          genießen. <br />
          Filial-Website: www.admiral.ag
        </p>
      </div>
    ),
    link: "https://admiral.at",
    email: "service@admiral.at",
    phone: "+43 7412 5266 011",
    openingHours: [
      {
        day: "Mo-So",
        time: "00:00-24:00 Uhr",
      },
    ],
  },
  {
    name: "Shoe4You",
    image: Shoe4YouLogo,
    description: "",
    link: "https://shoe4you.com",
    phone: "00 800 80 100 100",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:30-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:30-17:00 Uhr",
      },
    ],
  },
  {
    name: "JYSK",
    image: JYSKLogo,
    description: "",
    link: "https://jysk.at",
    phone: "07412 562500",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "PAGRO DISKONT",
    image: PagroLogo,
    description: "",
    link: "https://pagro.at",
    phone: "07412 53072",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:30-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "08:30-17:00 Uhr",
      },
    ],
  },
  {
    name: "Pet's Royal",
    image: PetsRoyalLogo,
    description: "",
    link: "https://pets-royal-shopping-center.business.site/",
    phone: "0660 9105535",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "Unimarkt",
    image: UnimarktLogo,
    description: "",
    link: "https://unimarkt.at",
    phone: "07412 53560",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:00-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "08:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "Takko Fashion",
    image: TakkoLogo,
    description: "",
    link: "https://takkko.com",
    phone: "07412 56184",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-18:00 Uhr",
      },
    ],
  },
];

export default shops.sort((a, b) => a.name.localeCompare(b.name));
