import React, { useEffect, useState } from "react";
import BigHeader from "./components/BigHeader";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Start from "./pages/Start";
import Imprint from "./pages/Imprint";
import DataPrivacy from "./pages/DataPrivacy";
import Navbar from "./components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import Shops from "./pages/Shops";
import News from "./pages/News";
import Contact from "./pages/Contact";
import SmallHeader from "./components/SmallHeader";
import ScrollToTop from "./components/ScrollToTop";
import Halloween from "./pages/Halloween";

export default function App() {
  const [isContactPage, setIsContactPage] = useState(false);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div className={"bg-gray-100 w-full flex justify-center"}>
      <div className="py-md-12 bg-gray-100 overflow-y-hidden scroll-smooth w-full">
        {/* Code block starts */}
        {/*<Navbar/>*/}

        <ScrollToTop />
        <Navbar />

        <div className={"w-full lg:container mx-auto bg-white"}>
          {/*<Header/>*/}

          <Routes>
            <Route
              path={"/"}
              element={
                <>
                  <BigHeader />
                  <Start />
                  <Footer />
                </>
              }
            />

            <Route
              path={"/halloween"}
              element={
                <>
                  <SmallHeader />
                  <Halloween />
                </>
              }
            />
            <Route
              path={"/shops"}
              element={
                <>
                  <SmallHeader />
                  <Shops />
                  <Footer />
                </>
              }
            />
            <Route
              path={"/news-und-aktionen"}
              element={
                <>
                  <SmallHeader />
                  <News />
                  <Footer />
                </>
              }
            />
            <Route
              path={"/kontakt"}
              element={
                <>
                  <SmallHeader />
                  <Contact />
                  <Footer hideContact={true} />
                </>
              }
            />
            <Route
              path={"/impressum"}
              element={
                <>
                  <SmallHeader />
                  <Imprint />
                  <Footer />
                </>
              }
            />
            <Route
              path={"/datenschutz"}
              element={
                <>
                  <SmallHeader />
                  <DataPrivacy />
                  <Footer />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}
