import { Blog } from "../models/blog";

const news: Blog[] = [
  // {
  //   image: Nikolo,
  //   title: "DER NIKOLAUS KOMMT!",
  //   content: (
  //     <div>
  //       <p>
  //         <strong>Wann?</strong>
  //       </p>
  //       <p>Samstag 02.12.2023 von 10 - 12 Uhr</p>
  //       <p className={"mt-2"}>
  //         Am Samstag, 2. Dezember 2023 kommen der Nikolaus und ein Engel ins B1
  //         Center in Ybbs und verteilen Geschenke an die kleinen Besucherinnen
  //         und Besucher! Und wer möchte und sich traut, kann sich mit dem
  //         Nikolaus gerne auch fotografieren lassen!
  //       </p>
  //     </div>
  //   ),
  // },
];

export default news;
