import React from "react";

type TitleOptions = {
  children: JSX.Element | JSX.Element[] | string
}

export default function Title(opt: TitleOptions) {
  return (
      <h1 className="xl:text-5xl md:text-3xl text-2xl  py-12 text-center text-gray-800 font-normal mb-4 pt-4 aos-init"
          {...opt}>
        {opt.children}
      </h1>
  )
}
