import ShopsOverview from "../components/ShopsOverview";
import React from "react";
import ImportantInfo from "../components/ImportantInfo";
import { useTitle } from "../hooks/useTitle";

export default function Start() {
  useTitle("Herzlich Willkommen");

  return (
    <>
      <ImportantInfo />
      {/* CONTENT GOES HERE */}
      <ShopsOverview />
    </>
  );
}
