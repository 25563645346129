import React from "react";
import { useTitle } from "../hooks/useTitle";
import Title from "../components/common/Title";

export default function DataPrivacy() {
  useTitle("Datenschutz");

  return (
    <section id={"datenschutz"} className={"py-12 lg:py-24"}>
      <Title>Datenschutzerklärung</Title>

      <div className="mx-auto px-14 lg:px-0 lg:mx-16">
        <div className="w-full place-items-center place-content-center text-center">
          <p>
            In folgender Datenschutzerklärung informieren wir Sie über die
            wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
            Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf
            Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
            Telekommunikationsgesetz 2003).
          </p>

          <p>
            Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese
            besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der
            Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein
            berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Kontakt mit uns</span>
            <br />
            Wenn Sie uns, entweder über unser Kontaktformular auf unserer
            Webseite, oder per Email kontaktieren, dann werden die von Ihnen an
            uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für
            den Fall von weiteren Anschlussfragen für sechs Monate bei uns
            gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe
            Ihrer übermittelten Daten.
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Cookies</span>
            <br />
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich
            um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
            abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies
            dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies
            bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie
            ermöglichen es uns, Ihren Browser beim nächsten Besuch
            wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren
            Browser so einrichten, dass er Sie über das Setzen von Cookies
            informiert und Sie dies nur im Einzelfall erlauben. Bei der
            Deaktivierung von Cookies kann die Funktionalität unserer Website
            eingeschränkt sein.
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Google Maps</span>
            <br />
            Unsere Website verwendet Funktionen des Webkartendienstes „Google
            Maps“. Der Dienstanbieter dieser Funktion ist:
            <br />
            <br />
            <span className={" "}>
              Google Ireland Limited Gordon House, Barrow Street Dublin 4.
              Ireland. Tel: +353 1 543 1000
            </span>
          </p>
          <p className={"mt-4"}>
            Im Zuge der Nutzung von Google Maps ist es notwendig Ihre IP-Adresse
            zu speichern und zu verarbeiten. Google überträgt in der Regel an
            einen Server in den USA und speichert die Daten dort. Die
            Verarbeitung geschieht durch den Diensteanbieter (oben genannt), der
            Betreiber dieser Homepage hat keinen Einfluss auf die Übertragung
            der Daten.
          </p>
          <p className={"mt-4"}>
            Die Datenverarbeitung erfolgt auf Basis der gesetzlichen
            Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f
            (berechtigtes Interesse) der DSGVO. Die Nutzung von Google Maps
            erhöht die Auffindbarkeit der Orte, welche auf unserer Webseite
            bereitgestellt werden.
          </p>
          <p className={"mt-4"}>
            Weitere Informationen über den Umgang mit Nutzerdaten des
            Diensteanbieters „Google“ können Sie der Datenschutzerklärung
            entnehmen:
            <br />
            <a href={"https://policies.google.com/privacy?hl=de"}>
              https://policies.google.com/privacy?hl=de
            </a>
          </p>
          <p className={"mt-4"}>
            Google verarbeitet die Daten auch in den USA, hat sich jedoch dem
            EU-US Privacy-Shield unterworfen.
            <br />
            <a href={"https://www.privacyshield.gov/EU-US-Framework"}>
              https://www.privacyshield.gov/EU-US-Framework
            </a>
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Google Fonts</span>
            <br />
            Unsere Website verwendet Schriftarten von „Google Fonts“. Der
            Dienstanbieter dieser Funktion ist:
            <br />
            <br />
            <span className={" "}>
              Google Ireland Limited Gordon House, Barrow Street Dublin 4.
              Ireland. Tel: +353 1 543 1000
            </span>
            <br />
            Tel: +353 1 543 1000
          </p>

          <p className={"mt-4"}>
            Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und
            speichert diese in den Cache. Da Sie, als Besucher der Webseite,
            Daten des Dienstanbieters empfangen kann Google unter Umständen
            Cookies auf Ihrem Rechner setzen oder analysieren.
          </p>

          <p className={"mt-4"}>
            Die Nutzung von „Google-Fonts“ dient der Optimierung unserer
            Dienstleistung und der einheitlichen Darstellung von Inhalten. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DSGVO dar.
          </p>

          <p className={"mt-4"}>
            Weitere Informationen zu Google Fonts erhalten Sie unter folgendem
            Link:
            <br />
            <a href={"https://developers.google.com/fonts/faq"}>
              https://developers.google.com/fonts/faq
            </a>
          </p>

          <p className={"mt-4"}>
            Weitere Informationen über den Umgang mit Nutzerdaten von Google
            können Sie der Datenschutzerklärung entnehmen:
            <br />
            <a href={"https://policies.google.com/privacy?hl=de"}>
              https://policies.google.com/privacy?hl=de
            </a>
          </p>

          <p className={"mt-4"}>
            Google verarbeitet die Daten auch in den USA, hat sich jedoch dem
            EU-US Privacy-Shield unterworfen.
            <br />
            <a href={"https://www.privacyshield.gov/EU-US-Framework"}>
              https://www.privacyshield.gov/EU-US-Framework
            </a>
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Server-Log Files</span>
            <br />
            Diese Webseite und der damit verbundene Provider erhebt im Zuge der
            Webseitennutzung automatisch Informationen im Rahmen sogenannter
            „Server-Log Files“. Dies betrifft insbesondere:
            <br />
            <br />
            <span className={" "}>IP-Adresse oder Hostname</span>
            <br />
            <span className={" "}>den verwendeten Browser</span>
            <br />
            <span className={" "}>
              Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit
            </span>
            <br />
            <span className={" "}>aufgerufene Seiten der Webseite</span>
            <br />
            <span className={" "}>Spracheinstellungen und Betriebssystem</span>
            <br />
            <span className={" "}>
              „Leaving-Page“ (auf welcher URL hat der Benutzer die Webseite
              verlassen)
            </span>
            <br />
            <span className={" "}>ISP (Internet Service Provider)</span>
            <br />
          </p>

          <p className={"mt-4"}>
            Diese erhobenen Informationen werden nicht personenbezogen
            verarbeitet oder mit personenbezogenen Daten in Verbindung gebracht.
          </p>
          <p className={"mt-4"}>
            Der Webseitenbetreiber behält es sich vor, im Falle von
            Bekanntwerden rechtswidriger Tätigkeiten, diese Daten auszuwerten
            oder zu überprüfen.
          </p>

          <p className={"mt-4"}>
            <span className={"font-medium"}>Ihre Rechte als Betroffener</span>
            <br />
            Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns
            gespeichert sind grundsätzlich ein Recht auf:
            <br />
            <br />
            <span className={" "}>Auskunft</span>
            <br />
            <span className={" "}>Löschung der Daten</span>
            <br />
            <span className={" "}>Berichtigung der Daten</span>
            <br />
            <span className={" "}>Übertragbarkeit der Daten</span>
            <br />
            <span className={" "}>
              Widerruf und Widerspruch zur Datenverarbeitung
            </span>
            <br />
            <span className={" "}>Einschränkung</span>
            <br />
          </p>

          <p className={"mt-4"}>
            Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten
            Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die
            Möglichkeit sich bei uns (immo@llb.at) oder der Datenschutzbehörde
            zu beschweren.
          </p>

          <p className={"mt-5"}>
            Sie erreichen uns unter folgenden Kontaktdaten:
            <br />
            <br />
            LLB Immo Kapitalanlagegesellschaft m.b.H.
            <br />
            1010 Wien, Heßgasse 1<br />
            Telefon: +43 1 536 16-0
            <br />
            E-Mail: immo@llb.at
            <br />
            <a href={"www.llbimmo.at"} target={"_blank"} rel="noreferrer">
              www.llbimmo.at
            </a>
            <br />
          </p>

          <p className={"font-light text-xs mt-5"}>
            Quelle: <a href={"fairesrecht.at"}>fairesRecht.at</a> in Kooperation
            mit <a href={"https://fairesspiel.at/"}>https://fairesspiel.at/</a>
          </p>
        </div>
      </div>
    </section>
  );
}
