import { useTitle } from "../hooks/useTitle";
import React from "react";
import Title from "../components/common/Title";
import ImportantInfo from "../components/ImportantInfo";
import halloween2 from "../images/news/halloween.jpg";

export default function Halloween() {
  useTitle("Halloween");

  return (
    <div className={"pb-12 lg:pb-24"}>
      <section
        id={"shops"}
        className={"py-12 lg:py-24 px-8 xl:px-24 text-center"}
      >
        <Title
          data-aos={"fade-left"}
          data-aos-duration={600}
          data-aos-delay={0}
        >
          Kinder Halloween Party!
        </Title>

        <img
          src={halloween2}
          className={"rounded-2xl mt-8 h-96 w-auto object-cover mx-auto"}
        />

        <p className={"mt-12"}>
          Freut Ihr Euch schon auf Halloween? Wir verkürzen Eure Vorfreude
          darauf, denn bereits am <strong>Samstag, den 28. Oktober 2023</strong>{" "}
          veranstalten wir im B1 in Ybbs/Donau eine{" "}
          <strong>Kinder-Halloween-Party</strong>.
        </p>

        <p className={"xl:mt-8 mt-4"}>
          Wir freuen uns schon sehr auf Euch und Eure kreativen Verkleidungen
          und bei unserer Halloween-Schminkstation könnt Ihr Euch den letzten{" "}
          <strong>Schliff</strong> für Euer schaurig-schönstes Gesicht abholen!
        </p>
        <p className={"xl:mt-8 mt-4"}>
          Dreht am <strong>Halloween-Glücksrad</strong> und schnappt Euch die{" "}
          <strong>lustigsten und schaurigsten Sofortpreise</strong> - und für
          die{" "}
          <strong>
            schönsten, kreativsten und schaurig-schönsten Verkleidungen gibts um
            11 und um 14 Uhr noch einen Sonderpreis
          </strong>{" "}
          - also, bereitet Euch schon mal vor! Wir sehen uns am{" "}
          <strong>28. Oktober ab 10 Uhr</strong> im B1 Center!
        </p>
      </section>
      <ImportantInfo />
    </div>
  );
}
