import { Blog } from "../../models/blog";
import { useState } from "react";
import NewsModal from "./NewsModal";

export default function NewsEntry({
  news,
  index,
}: {
  news: Blog;
  index: number;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        className={"flex flex-col cursor-pointer"}
        onClick={() => setIsModalOpen(true)}
      >
        <img
          className={`w-full object-cover cursor-pointer rounded-2xl h-auto`}
          src={news.image}
          onClick={() => setIsModalOpen(true)}
        />
        <div
          className={
            "mt-3 text-md text-center md:text-xl lg:text-2xl font-bold"
          }
        >
          {news.title}
        </div>
      </div>

      <NewsModal
        news={news}
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </>
  );
}
